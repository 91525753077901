import { getMyself } from '@/api/profile'
import { getRequest } from '@/api/requests'
import { getToken } from '@/utils/cookie'
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/applications/list',
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
  },
  {
    path: '/applications',
    name: 'Requests',
    redirect: '/applications/list',
    children: [
      {
        path: 'list',
        name: 'RequestsView',
        meta: { title: 'Заявки' },
        component: () => import('../views/RequestsView.vue'),
      },
      {
        path: 'create',
        name: 'RequestCreate',
        meta: { title: 'Создать заявку' },
        component: () => import('../components/requests/RequestCreate.vue'),
        beforeEnter: async (to) => {
          const { store } = to.query
          // задаем пропсы
          to.matched[1].props.default = {
            code: +store,
          }
        },
      },
      {
        path: 'edit/:id/draft',
        name: 'RequestEditDraft',
        meta: { title: 'Редактировать черновик' },
        component: () => import('../components/requests/RequestUpdate.vue'),
        beforeEnter: async (to) => {
          const store = useStore()
          const {
            store: id,
            gi,
            comments,
            installation,
            dismantling,
          } = store.state.drafts.drafts[to.params.id]

          // задаем пропсы
          to.matched[1].props.default = {
            code: id,
            potencialGI: gi,
            comment: comments,
            installation,
            dismantling,
          }
        },
      },
      {
        path: 'edit/:id',
        name: 'RequestEdit',
        meta: { title: 'Редактировать заявку' },
        component: () => import('../components/requests/RequestUpdate.vue'),
        beforeEnter: async (to) => {
          const {
            store: { id },
            gi,
            comments,
            installation,
            dismantling,
          } = await getRequest(to.params.id)
          // задаем пропсы
          to.matched[1].props.default = {
            code: id,
            potencialGI: gi,
            comment: comments,
            installation,
            dismantling,
          }
        },
      },
      {
        path: 'view/:id',
        name: 'RequestDetail',
        meta: { title: 'Просмотр заявки' },
        component: () => import('../components/requests/RequestDetail.vue'),
      },
      {
        path: 'view/:id/draft',
        name: 'RequestDetailDraft',
        meta: { title: 'Просмотр заявки' },
        component: () => import('../components/requests/RequestDetail.vue'),
      },
      {
        path: 'view/:id/approve',
        name: 'RequestApprove',
        meta: { title: 'Подписать заявку' },
        component: () => import('../components/requests/RequestApprove.vue'),
      },
      {
        path: 'view/:id/revoke',
        name: 'RequestRevoke',
        meta: { title: 'Отозвать подпись' },
        component: () => import('../components/requests/RequestRevoke.vue'),
      },
      {
        path: 'view/:id/photoreports',
        name: 'PhotoreportDetail',
        meta: { title: 'Просмотр фотоотчетов заявки' },
        component: () =>
          import('../components/photoreports/PhotoreportDetail.vue'),
      },
      {
        path: 'view/:id/photoreports/photo',
        name: 'PhotoreportPhoto',
        meta: { title: 'Просмотр фото фотоотчетов' },
        component: () =>
          import('../components/photoreports/PhotoreportPhoto.vue'),
      },
      {
        path: 'view/:id/tasks/:taskId',
        name: 'TaskDetail',
        meta: { title: 'Просмотр задания' },
        component: () => import('../components/tasks/TaskDetail.vue'),
      },
    ],
  },
  // {
  //   path: '/segments',
  //   name: 'Segments',
  //   redirect: '/segments/list',
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'SegmentsList',
  //       meta: { title: 'Сегменты' },
  //       component: () =>
  //         import(
  //           '../views/crud/SegmentTable.vue'
  //         ),
  //     },
  //     {
  //       path: 'create',
  //       name: 'SegmentsCreate',
  //       meta: { title: 'Новый сегмент' },
  //       props: {
  //         create: true,
  //       },
  //       component: () =>
  //         import(
  //           '../views/crud/SegmentForm.vue'
  //         ),
  //     },
  //     {
  //       path: 'edit/:id',
  //       name: 'SegmentsEdit',
  //       meta: { title: 'Редактировать сегмент' },
  //       component: () =>
  //         import(
  //           '../views/crud/SegmentForm.vue'
  //         ),
  //     },
  //     {
  //       path: ':id/append',
  //       name: 'SegmentsAddStore',
  //       meta: { title: 'Добавить магазины сегмента' },
  //       props: {
  //         create: true,
  //       },
  //       component: () =>
  //         import(
  //           '../views/crud/SegmentStoreForm.vue'
  //         ),
  //     },
  //     {
  //       path: ':id/remove',
  //       name: 'SegmentsRemoveStore',
  //       meta: { title: 'Удалить магазины сегмента' },
  //       component: () =>
  //         import(
  //           '../views/crud/SegmentStoreForm.vue'
  //         ),
  //     },
  //   ],
  // },
  // {
  //   path: '/layouts',
  //   name: 'Layouts',
  //   // redirect: '/layouts/list',
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'LayoutsList',
  //       meta: { title: 'Макеты' },
  //       component: () =>
  //         import(
  //           '../views/LayoutsView.vue'
  //         ),
  //     },
  //     {
  //       path: 'view/:layoutId',
  //       name: 'LayoutsDetail',
  //       meta: { title: 'Просмотр макета' },
  //       component: () =>
  //         import(
  //           '../components/layouts/LayoutsDetail.vue'
  //         ),
  //     },
  //   ],
  // },
  {
    path: '/routes',
    redirect: '/routes/list',
    name: 'Routes',
    children: [
      {
        path: 'list',
        name: 'RoutesList',
        meta: { title: 'Маршруты' },
        component: () => import('../views/RoutesView.vue'),
      },
      {
        path: 'view/:routeId',
        name: 'RouteDetail',
        meta: { title: 'Просмотр маршрута' },
        component: () => import('../components/layouts/LayoutsDetail.vue'),
      },
      {
        path: '/routes/:segmentId',
        name: 'SegmentView',
        meta: { title: 'Просмотр сегмента' },
        component: () => import('../views/SegmentStoresView.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to) => {
  const token = getToken()
  const { name } = to
  if (token && name === 'Auth') {
    return { name: 'Home' }
  } else if (!token && name !== 'Auth') {
    return { name: 'Auth' }
  }

  if (to.name === 'RequestsView' && !Object.keys(to.query).length) {
    let me = undefined
    let isSales = false

    try {
      me = await getMyself()
      isSales = me.roles.includes('sales')
    } catch (error) {
      console.log(error)
    }

    if (isSales) {
      return { name: to.name, query: { 'me.author': 'true' } }
    }

    return { name: to.name, query: { 'me.author': 'false' } }
  }
})

export default router
