const TokenKey = 't2_photobinding_access_token'
const TokenRefreshKey = 't2_photobinding_refresh_token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function getRefreshToken() {
  return localStorage.getItem(TokenRefreshKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}
export function setRefreshToken(token) {
  return localStorage.setItem(TokenRefreshKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}
export function removeRefreshToken() {
  return localStorage.removeItem(TokenRefreshKey)
}
