<template>
  <div class="dropdown">
    <ul>
      <slot />
    </ul>
    <div v-if="version" class="dropdown__caption">v{{ version }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    version() {
      return process.env.VUE_APP_VERSION
    },
  },
  mounted() {
    document.addEventListener('click', this.onClose, true)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onClose, true)
  },
  methods: {
    onClose(e) {
      if (this.$el.contains(e.target)) return
      else this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  min-width: 160px;
  background-color: #fff;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 12px 12px;
  margin-top: 6px;
  z-index: 100;
  ul {
    @include reset-list;
    &:deep(li) {
      a,
      button {
        display: block;
        width: 100%;
        padding: 6px 0;
        text-align: left;
        font-weight: normal;
      }
    }
  }
  &__caption {
    position: absolute;
    bottom: 0;
    right: 4px;
    pointer-events: none;
    opacity: 0.3;
  }
}
</style>
